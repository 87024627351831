/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3b3a2bb0"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,CrmObjectSyncAlert: require('/code/src/components/widgets/CrmObjectSyncAlert.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,AddressAlert: require('/code/src/components/widgets/AddressAlert.vue').default,VerticalTable: require('/code/src/components/table/VerticalTable.vue').default,LabelText: require('/code/src/components/text/LabelText.vue').default,CrmObjectSyncInfoSection: require('/code/src/components/widgets/CrmObjectSyncInfoSection.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,DateInput: require('/code/src/components/input/DateInput.vue').default,Input: require('/code/src/components/input/Input.vue').default,ShippingStatusTag: require('/code/src/components/widgets/ShippingStatusTag.vue').default,CustomizableHeaderTable: require('/code/src/components/table/CustomizableHeaderTable.vue').default,SplitView: require('/code/src/components/SplitView.vue').default})
