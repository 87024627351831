import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "form": "",
      "loading": _vm.loading,
      "title": _vm.$t('contacts.id.additionalInfo'),
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.$t('action.save'),
      "disabled-positive": !_vm.isSubmitEnable,
      "before-cancel": _vm.beforeCancel
    },
    on: {
      "click:positive": _vm.onClickSave,
      "close": _vm.resetForm
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "value": _vm.form.field,
      "initial-value": _vm.initialFormData.field,
      "type": "combobox",
      "items": _vm.fieldItems,
      "item-text": "field",
      "item-value": "id",
      "label": "フィールド名",
      "required": ""
    },
    on: {
      "input": _vm.selectedField
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('DateInput', {
    attrs: {
      "initial-value": _vm.initialFormData.date,
      "label": "日付",
      "required": ""
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };