import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm$contact, _vm$contact3;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('contactDetail')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('FloatingTooltip', {
    attrs: {
      "left": "",
      "disabled": !((_vm$contact = _vm.contact) !== null && _vm$contact !== void 0 && _vm$contact.delivery_block),
      "max-width": "400px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$contact2;
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('button', {
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button",
            "disabled": (_vm$contact2 = _vm.contact) === null || _vm$contact2 === void 0 ? void 0 : _vm$contact2.delivery_block
          },
          on: {
            "click": _vm.onClickSend
          }
        }, [_vm._v("\n            " + _vm._s(_vm.$t('action.sendTouch')) + "\n          ")])])];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t('contacts.id.deliveryBlock')))])])], 1), _vm._v(" "), ((_vm$contact3 = _vm.contact) === null || _vm$contact3 === void 0 ? void 0 : _vm$contact3.sync_status) !== 'unsynchronized' ? _c('CrmObjectSyncAlert', {
    staticClass: "mt-4",
    attrs: {
      "crm-sync-object": _vm.contact
    },
    on: {
      "click:sync": _vm.syncToCrm,
      "click:unsync": _vm.unsyncFromCrm
    }
  }) : _vm._e(), _vm._v(" "), _c('SplitView', {
    ref: "splitView",
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.mdAndDown
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        var _vm$contact4, _vm$contact7, _vm$contact7$descript, _vm$contact8, _vm$contact9, _vm$contact10, _vm$contact10$additio;
        return [_c('ContentArea', [_c('div', {
          staticClass: "d-flex mb-4 align-center"
        }, [_c('h6', [_vm._v(_vm._s(_vm.$t('contactInfo')))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.$config.public.debug && (_vm$contact4 = _vm.contact) !== null && _vm$contact4 !== void 0 && _vm$contact4.title ? [_c('button', {
          staticClass: "tw-btn tw-btn-text-secondary !tw-p-2",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.deleteSalutation
          }
        }, [_vm._v("\n              " + _vm._s('敬称削除(デバッグ)') + "\n            ")])] : _vm._e(), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
          attrs: {
            "type": "button",
            "disabled": _vm.loading.initial
          },
          domProps: {
            "textContent": _vm._s(_vm.$t('action.edit'))
          },
          on: {
            "click": function click($event) {
              _vm.dialog.editBasic = true;
            }
          }
        })], 2), _vm._v(" "), _c('VerticalTable', {
          attrs: {
            "headers": [{
              text: _vm.$t('name'),
              value: 'name',
              getter: _vm.$fullName,
              half: true
            }, {
              text: _vm.$t('salutation'),
              value: 'title',
              half: true
            }, {
              text: _vm.$t('email'),
              value: 'email'
            }, {
              text: _vm.$t('phone'),
              value: 'tel'
            }, {
              text: _vm.$t('address'),
              value: 'address'
            }, {
              text: _vm.$t('accountName'),
              value: 'accountName'
            }, {
              text: _vm.$t('department'),
              value: 'department',
              half: true
            }, {
              text: _vm.$t('jobTitle'),
              value: 'job_title',
              half: true
            }, {
              text: _vm.$t('note'),
              value: 'description'
            }, {
              text: _vm.$t('defaultAddressAsDestination'),
              value: 'primary_shipping_address'
            }, {
              text: _vm.$t('owner'),
              value: 'owner',
              getter: function getter(val) {
                return _vm.$fullName(val.owner);
              }
            }],
            "item": _vm.contact,
            "dummy": _vm.loading.initial
          },
          scopedSlots: _vm._u([{
            key: "item.address",
            fn: function fn(_ref2) {
              var _vm$contact5, _vm$contact5$shipping, _vm$contact6;
              var shipping_addresses = _ref2.shipping_addresses;
              return [_vm._l(shipping_addresses, function (address, index) {
                return _c('AddressLabel', {
                  key: index,
                  class: {
                    'mt-2': index > 0
                  },
                  attrs: {
                    "detail": "",
                    "rows": "",
                    "address": address
                  }
                });
              }), _vm._v(" "), !((_vm$contact5 = _vm.contact) !== null && _vm$contact5 !== void 0 && (_vm$contact5$shipping = _vm$contact5.shipping_addresses) !== null && _vm$contact5$shipping !== void 0 && _vm$contact5$shipping.length) ? _c('span', {
                staticClass: "text-body-2 disabled-text--text"
              }, [_vm._v("\n              " + _vm._s(_vm.$t('notSet')) + "\n            ")]) : _vm._e(), _vm._v(" "), (_vm$contact6 = _vm.contact) !== null && _vm$contact6 !== void 0 && _vm$contact6.delivery_block ? _c('div', {
                staticClass: "mt-1"
              }, [_c(VIcon, {
                staticClass: "mr-1",
                attrs: {
                  "color": "error",
                  "size": "20"
                }
              }, [_vm._v("\n                mdi-minus-circle\n              ")]), _vm._v(" "), _c('span', {
                staticClass: "text-body-1 error--text"
              }, [_vm._v("\n                " + _vm._s(_vm.$t('warning.blockDelivery')) + "\n              ")])], 1) : _vm._e()];
            }
          }, {
            key: "item.accountName",
            fn: function fn(_ref3) {
              var crm_company_name = _ref3.crm_company_name,
                account = _ref3.account;
              return [account ? _c('LinkText', {
                attrs: {
                  "to": "/accounts/".concat(account === null || account === void 0 ? void 0 : account.id)
                },
                domProps: {
                  "textContent": _vm._s(account === null || account === void 0 ? void 0 : account.name)
                }
              }) : crm_company_name ? _c('span', [_vm._v("\n              " + _vm._s(crm_company_name) + "\n            ")]) : _c('div', {
                staticClass: "text-body-2 disabled-text--text"
              }, [_vm._v("\n              " + _vm._s(_vm.$t('notSet')) + "\n            ")])];
            }
          }, (_vm$contact7 = _vm.contact) !== null && _vm$contact7 !== void 0 && (_vm$contact7$descript = _vm$contact7.description) !== null && _vm$contact7$descript !== void 0 && _vm$contact7$descript.trim() ? {
            key: "item.description",
            fn: function fn(_ref4) {
              var description = _ref4.description;
              return [_c('pre', {
                staticClass: "pa-0 text-body-1",
                staticStyle: {
                  "max-height": "160px",
                  "overflow-y": "scroll"
                },
                domProps: {
                  "textContent": _vm._s(description)
                }
              })];
            }
          } : null, (_vm$contact8 = _vm.contact) !== null && _vm$contact8 !== void 0 && _vm$contact8.primary_shipping_address ? {
            key: "item.primary_shipping_address",
            fn: function fn(_ref5) {
              var primary_shipping_address = _ref5.primary_shipping_address;
              return [_c('AddressLabel', {
                attrs: {
                  "detail": "",
                  "rows": "",
                  "address": primary_shipping_address
                }
              }), _vm._v(" "), _c('AddressAlert', {
                staticClass: "mt-2",
                attrs: {
                  "address": primary_shipping_address
                }
              })];
            }
          } : null], null, true)
        }), _vm._v(" "), _c('div', {
          staticClass: "d-flex align-center mb-2 mt-6"
        }, [_c('h6', [_vm._v(_vm._s(_vm.$t('tag')))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
          attrs: {
            "type": "button",
            "disabled": _vm.loading.initial
          },
          domProps: {
            "textContent": _vm._s(_vm.$t('action.edit'))
          },
          on: {
            "click": function click($event) {
              _vm.dialog.editTag = true;
            }
          }
        })], 1), _vm._v(" "), (_vm$contact9 = _vm.contact) !== null && _vm$contact9 !== void 0 && _vm$contact9.tags.length ? _c('div', _vm._l(_vm.contact.tags, function (tag) {
          return _c(VChip, {
            key: tag,
            staticClass: "tw-mr-2 tw-mb-2",
            attrs: {
              "outlined": "",
              "small": ""
            }
          }, [_c('span', {
            directives: [{
              name: "ellipsis",
              rawName: "v-ellipsis"
            }],
            staticClass: "tw-max-w-[160px]"
          }, [_vm._v("\n              " + _vm._s(tag) + "\n            ")])]);
        }), 1) : _c('div', {
          staticClass: "text-caption disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('notSet')) + "\n        ")]), _vm._v(" "), _c('div', {
          staticClass: "d-flex align-center mb-2 mt-6"
        }, [_c('h6', [_vm._v(_vm._s(_vm.$t('contacts.id.additionalInfo')))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
          attrs: {
            "type": "button",
            "disabled": _vm.loading.initial
          },
          domProps: {
            "textContent": _vm._s(_vm.$t('action.add'))
          },
          on: {
            "click": function click($event) {
              _vm.dialog.editAdditionalDate = true;
            }
          }
        })], 1), _vm._v(" "), _c(VRow, {
          attrs: {
            "no-gutters": ""
          }
        }, [(_vm$contact10 = _vm.contact) !== null && _vm$contact10 !== void 0 && (_vm$contact10$additio = _vm$contact10.additional_date) !== null && _vm$contact10$additio !== void 0 && _vm$contact10$additio.length ? _vm._l(_vm.contact.additional_date, function (additional) {
          return _c(VCol, {
            key: additional.id,
            attrs: {
              "cols": "12",
              "sm": "6",
              "md": "12"
            }
          }, [_c('LabelText', {
            staticClass: "mt-2",
            attrs: {
              "x-small": "",
              "label": additional.label
            }
          }, [_c('div', {
            staticClass: "text-body-2 d-flex align-center"
          }, [_vm._v("\n                  " + _vm._s(_vm.$date(additional.date)) + "\n                  "), _c('button', {
            staticClass: "tw-btn tw-btn-icon-x-small tw-btn-icon-info",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function click($event) {
                return _vm.onClickDeleteAdditionalDate(additional);
              }
            }
          }, [_c('mdi-delete', {
            staticClass: "tw-size-4"
          })], 1)])])], 1);
        }) : _c(VCol, [_c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n              " + _vm._s(_vm.$t('none')) + "\n            ")])])], 2), _vm._v(" "), _c('CrmObjectSyncInfoSection', {
          staticClass: "mt-6",
          attrs: {
            "crm-sync-object": _vm.contact
          },
          on: {
            "update:crmSyncObject": function updateCrmSyncObject($event) {
              _vm.contact = $event;
            },
            "update:crm-sync-object": function updateCrmSyncObject($event) {
              _vm.contact = $event;
            },
            "click:sync": _vm.syncToCrm,
            "click:unsync": _vm.unsyncFromCrm
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "second",
      fn: function fn() {
        return [_c('ContentArea', {
          staticClass: "right-column"
        }, [_c('h6', {
          staticClass: "mb-6"
        }, [_vm._v(_vm._s(_vm.$t('shippingStats')))]), _vm._v(" "), _c(VRow, {
          staticClass: "mb-6"
        }, [_c(VCol, {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('DateInput', {
          attrs: {
            "value": [_vm.filterOptions.beforeDate, _vm.filterOptions.afterDate],
            "type": "range",
            "max-date": _vm.$iso8601(_vm.$nextYear),
            "label": _vm.$t('desiredArrivalOrIssueDate')
          },
          on: {
            "input": function input($event) {
              _vm.filterOptions.beforeDate = $event === null || $event === void 0 ? void 0 : $event[0];
              _vm.filterOptions.afterDate = $event === null || $event === void 0 ? void 0 : $event[1];
            }
          }
        })], 1), _vm._v(" "), _c(VCol, {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('Input', {
          attrs: {
            "type": "select",
            "multiple": "",
            "label": _vm.$t('status'),
            "items": _vm.statusItem,
            "item-text": "name",
            "item-value": "id",
            "placeholder": _vm.$t('placeholder.status')
          },
          model: {
            value: _vm.filterOptions.selectStatus,
            callback: function callback($$v) {
              _vm.$set(_vm.filterOptions, "selectStatus", $$v);
            },
            expression: "filterOptions.selectStatus"
          }
        })], 1)], 1), _vm._v(" "), _c('OrderStats', {
          staticClass: "mb-6",
          attrs: {
            "stats": _vm.stats,
            "dummy": _vm.loading.stats
          }
        }), _vm._v(" "), _c('CustomizableHeaderTable', {
          attrs: {
            "sort-desc": ['shipping_date'],
            "headers": [{
              text: 'ステータス',
              value: 'get_status_display'
            }, {
              text: 'キャンペーン名',
              value: 'campaign.name',
              multiLine: true
            }, {
              text: 'タッチ名',
              value: 'touch_name',
              multiLine: true,
              hideable: false
            },
            // TODO APIの対応が必要 { text: '商品名', value: '', multiLine: true },
            // TODO APIの対応が必要 { text: '商品バリエーション', value: '', multiLine: true },
            // TODO APIの対応が必要 { text: '商品価格', value: '', multiLine: true },
            // TODO APIの対応が必要 { text: 'QR遷移先URL', value: '', empty: $t('none') },
            // TODO APIの対応が必要 { text: 'WOWリンク', value: '', empty: $t('none') },
            {
              text: 'オーダー作成者',
              value: 'created_by.name'
            }, {
              text: 'オーダー発注日',
              value: 'order_date',
              transform: _vm.$date,
              empty: _vm.$t('notOrder')
            }, {
              text: 'オーダー作成日',
              value: 'created_at',
              transform: _vm.$date
            }, {
              text: '到着希望日/発行日',
              value: 'shipping_date'
            }, {
              text: 'QR反響/クリック',
              value: 'touched_at',
              empty: _vm.$t('none')
            }, {
              text: 'コスト',
              value: 'price',
              align: 'center',
              transform: _vm.$price
            }, {
              text: 'コメント数',
              value: 'comment_count',
              align: 'center'
            }, {
              text: '承認者',
              value: 'authorizer',
              getter: function getter(val) {
                var _val$authorizer$name, _val$authorizer, _val$assigned_to;
                return (_val$authorizer$name = (_val$authorizer = val.authorizer) === null || _val$authorizer === void 0 ? void 0 : _val$authorizer.name) !== null && _val$authorizer$name !== void 0 ? _val$authorizer$name : (_val$assigned_to = val.assigned_to) === null || _val$assigned_to === void 0 ? void 0 : _val$assigned_to.name;
              },
              empty: _vm.$t('notSet')
            }],
            "default-headers": ['キャンペーン名', 'タッチ名', 'オーダー作成者', '到着希望日/発行日', 'ステータス', 'QR反響/クリック', 'コスト', 'コメント数'],
            "items": _vm.order_items,
            "item-key": "id",
            "loading": _vm.loading.order,
            "options": _vm.tableOptions,
            "server-items-length": _vm.totalItems,
            "disable-sort": true
          },
          on: {
            "update:options": function updateOptions($event) {
              _vm.tableOptions = $event;
            },
            "click:row": function clickRow(item, _, event) {
              return _vm.onClickItem(item, event);
            },
            "auxclick:row": function auxclickRow(event, row) {
              return _vm.onClickItem(row.item, event);
            }
          },
          scopedSlots: _vm._u([{
            key: "item.get_status_display",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c('ShippingStatusTag', {
                attrs: {
                  "text": item.get_status_display,
                  "status": item.status
                }
              })];
            }
          }, {
            key: "item.campaign.name",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [item.campaign ? _c('LinkText', {
                attrs: {
                  "to": "/campaign/".concat(item.campaign.id)
                }
              }, [_vm._v("\n              " + _vm._s(item.campaign.name) + "\n            ")]) : _vm._e()];
            }
          }, {
            key: "item.touch_name",
            fn: function fn(_ref8) {
              var _item$order_line_item, _item$order_line_item2, _item$order_line_item3, _item$order_line_item4, _item$order_line_item5;
              var item = _ref8.item;
              return [(_item$order_line_item = item.order_line_items) !== null && _item$order_line_item !== void 0 && _item$order_line_item[0] ? _c('LinkText', {
                attrs: {
                  "to": "/touch/".concat((_item$order_line_item2 = item.order_line_items) === null || _item$order_line_item2 === void 0 ? void 0 : (_item$order_line_item3 = _item$order_line_item2[0]) === null || _item$order_line_item3 === void 0 ? void 0 : _item$order_line_item3.touch)
                }
              }, [_vm._v("\n              " + _vm._s((_item$order_line_item4 = item.order_line_items) === null || _item$order_line_item4 === void 0 ? void 0 : (_item$order_line_item5 = _item$order_line_item4[0]) === null || _item$order_line_item5 === void 0 ? void 0 : _item$order_line_item5.name) + "\n            ")]) : _vm._e()];
            }
          }, {
            key: "item.shipping_date",
            fn: function fn(_ref9) {
              var item = _ref9.item;
              return [!item.is_egift || item.status === 9 ? [_vm._v("\n              " + _vm._s(_vm.$date(item.shipping_date)) + "\n            ")] : _c('div', {
                staticClass: "text-caption disabled-text--text"
              }, [_vm._v("\n              " + _vm._s(_vm.$t('notIssue')) + "\n            ")])];
            }
          }, {
            key: "item.touched_at",
            fn: function fn(_ref10) {
              var _item$order_line_item6, _item$order_line_item7, _item$order_line_item8;
              var item = _ref10.item;
              return [item.touched_at ? [_vm._v("\n              " + _vm._s(_vm.$date(item.touched_at)) + "\n              "), _c('br'), _vm._v("\n              " + _vm._s(_vm.$time(item.touched_at)) + "\n            ")] : (_item$order_line_item6 = item.order_line_items) !== null && _item$order_line_item6 !== void 0 && (_item$order_line_item7 = _item$order_line_item6[0]) !== null && _item$order_line_item7 !== void 0 && (_item$order_line_item8 = _item$order_line_item7.line_item_lp) !== null && _item$order_line_item8 !== void 0 && _item$order_line_item8.opened_at ? [_vm._v("\n              " + _vm._s(_vm.$date(item.order_line_items[0].line_item_lp.opened_at)) + "\n              "), _c('br'), _vm._v("\n              " + _vm._s(_vm.$time(item.order_line_items[0].line_item_lp.opened_at)) + "\n            ")] : _vm._e()];
            }
          }, {
            key: "item.comment_count",
            fn: function fn(_ref11) {
              var item = _ref11.item;
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-comment-outline")]), _vm._v("\n            " + _vm._s(item.comment_count) + "\n          ")];
            }
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-justify-center tw-mt-6"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-outline-error",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onClickDelete
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.deletionContact')) + "\n    ")])]), _vm._v(" "), _vm.contact ? [_c('EditContactDialog', {
    attrs: {
      "show": _vm.dialog.editBasic
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "editBasic", $event);
      }
    },
    model: {
      value: _vm.contact,
      callback: function callback($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  }), _vm._v(" "), _c('EditAdditionalDateDialog', {
    attrs: {
      "show": _vm.dialog.editAdditionalDate
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "editAdditionalDate", $event);
      }
    },
    model: {
      value: _vm.contact,
      callback: function callback($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  }), _vm._v(" "), _c('ConfirmContactMigrationDialog', {
    ref: "confirmDialog",
    on: {
      "click:positive": _vm.sync
    }
  }), _vm._v(" "), _c('EditTagDialog', {
    attrs: {
      "show": _vm.dialog.editTag
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "editTag", $event);
      }
    },
    model: {
      value: _vm.contact,
      callback: function callback($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };