import { VChip } from 'vuetify/lib/components/VChip';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';
import Resize from 'vuetify/lib/directives/resize';

import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c(VFadeTransition, [_vm.value.length >= 10 ? _c('AlertArea', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "no-margin": "",
      "message": _vm.$t('warning.limitOverTags')
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c(VFadeTransition, {
    attrs: {
      "group": ""
    }
  }, [_vm._l(_vm.value, function (tag) {
    return _c(VChip, {
      key: "tag-".concat(tag),
      staticClass: "my-1 mr-2",
      attrs: {
        "outlined": "",
        "small": "",
        "close": "",
        "close-icon": "mdi-close"
      },
      on: {
        "click:close": function clickClose($event) {
          return _setup.unselect(tag);
        }
      }
    }, [_c('span', {
      directives: [{
        name: "ellipsis",
        rawName: "v-ellipsis"
      }],
      staticStyle: {
        "max-width": "160px"
      }
    }, [_vm._v("\n          " + _vm._s(tag) + "\n        ")])]);
  }), _vm._v(" "), _vm.value.length < 10 ? _c(VChip, {
    key: "add",
    staticClass: "my-1 mr-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": _setup.hasFocus ? null : 'primary'
    }
  }, [_c(VIcon, {
    attrs: {
      "small": "",
      "left": "",
      "color": _setup.hasFocus ? null : 'primary'
    },
    on: {
      "click": _setup.focusCombobox
    }
  }, [_vm._v("\n          mdi-plus\n        ")]), _vm._v(" "), _c(_setup.VCombobox, {
    directives: [{
      def: Resize,
      name: "resize",
      rawName: "v-resize",
      value: _setup.updateDropdownMenuHeight,
      expression: "updateDropdownMenuHeight"
    }, {
      def: Intersect,
      name: "intersect",
      rawName: "v-intersect",
      value: _setup.updateDropdownMenuHeight,
      expression: "updateDropdownMenuHeight"
    }],
    ref: "inputRef",
    staticClass: "small",
    attrs: {
      "items": _setup.without.apply(_setup, [_vm.items].concat(_toConsumableArray(_vm.value))),
      "dense": "",
      "hide-details": "",
      "height": "20",
      "maxlength": "255",
      "menu-props": _setup.dropdownMenuProps,
      "append-icon": null,
      "placeholder": _vm.$t('action.addTag')
    },
    on: {
      "focus": function focus($event) {
        _setup.hasFocus = true;
      },
      "blur": function blur($event) {
        _setup.hasFocus = false;
      },
      "change": _setup.select,
      "update:search-input": _setup.updateInputWidth
    }
  })], 1) : _vm._e()], 2)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };