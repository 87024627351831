var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "form": "",
      "loading": _vm.loading.save,
      "title": _vm.$t('tag'),
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.$t('action.save'),
      "disabled-positive": !_vm.isSubmitEnable,
      "before-cancel": _vm.beforeCancel
    },
    on: {
      "click:positive": _vm.onClickSave,
      "close": _vm.resetForm
    },
    model: {
      value: _vm.display,
      callback: function callback($$v) {
        _vm.display = $$v;
      },
      expression: "display"
    }
  }, [_c('TagSelect', {
    attrs: {
      "value": _vm.form.tags,
      "items": _vm.tags
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.form.tags = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };